/*-------------------------  ------------------------- */
/*------------------------- fonts ------------------------- */
@font-face {
	font-family: HEINEKEN Core;
	src: url('assets/fonts/HEINEKEN Core.eot');
	src: url('assets/fonts/HEINEKEN Core.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/HEINEKEN Core.woff') format('woff'), url('assets/fonts/HEINEKEN Core.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: HEINEKEN Core;
	src: url('assets/fonts/HEINEKEN Core-Bold.eot');
	src: url('assets/fonts/HEINEKEN Core-Bold.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/HEINEKEN Core-Bold.woff') format('woff'),
		url('assets/fonts/HEINEKEN Core-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: HEINEKEN Core;
	src: url('assets/fonts/HEINEKEN Core-ExtraBold.eot');
	src: url('assets/fonts/HEINEKEN Core-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/HEINEKEN Core-ExtraBold.woff') format('woff'),
		url('assets/fonts/HEINEKEN Core-ExtraBold.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: HEINEKEN Core;
	src: url('assets/fonts/HEINEKEN Core-Thin.eot');
	src: url('assets/fonts/HEINEKEN Core-Thin.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/HEINEKEN Core-Thin.woff') format('woff'),
		url('assets/fonts/HEINEKEN Core-Thin.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: HEINEKEN Core;
	src: url('assets/fonts/HEINEKEN Core-Italic.eot');
	src: url('assets/fonts/HEINEKEN Core-Italic.eot?#iefix') format('embedded-opentype'),
		url('assets/fonts/HEINEKEN Core-Italic.woff') format('woff'),
		url('assets/fonts/HEINEKEN Core-Italic.ttf') format('truetype');
	font-style: italic;
}
body {
	margin: 0;
	font-family: 'HEINEKEN Core';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.notistack-MuiContent {
	font-weight: bold !important;
}
